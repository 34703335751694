import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicAppleWatch = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2019_51084)">
        <path
          d="M8.68477 3.05512C8.85012 2.19116 9.60578 1.56641 10.4854 1.56641H15.3734C16.2397 1.56641 16.9878 2.17278 17.1671 3.02033L17.4468 4.34264C17.6879 5.48262 16.8184 6.55538 15.6532 6.55538H10.2324C9.08163 6.55538 8.21539 5.50764 8.4317 4.37743L8.68477 3.05512Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M17.2449 22.0777C17.0796 22.9417 16.3239 23.5664 15.4443 23.5664L10.5562 23.5664C9.68995 23.5664 8.94188 22.96 8.7626 22.1125L8.48289 20.7902C8.24175 19.6502 9.11133 18.5774 10.2765 18.5774L15.6973 18.5774C16.8481 18.5774 17.7143 19.6252 17.498 20.7554L17.2449 22.0777Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M5.8125 8.90299C5.8125 6.87795 7.45412 5.23633 9.47917 5.23633H16.4458C18.4709 5.23633 20.1125 6.87795 20.1125 8.90299V15.9823C20.1125 18.0073 18.4709 19.6489 16.4458 19.6489H9.47917C7.45412 19.6489 5.8125 18.0073 5.8125 15.9823V8.90299Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4109 7.69258C12.4109 7.38882 12.6572 7.14258 12.9609 7.14258C15.9985 7.14258 18.4609 9.60501 18.4609 12.6426C18.4609 15.6801 15.9985 18.1426 12.9609 18.1426C9.92337 18.1426 7.46094 15.6801 7.46094 12.6426C7.46094 12.3388 7.70718 12.0926 8.01094 12.0926C8.31469 12.0926 8.56094 12.3388 8.56094 12.6426C8.56094 15.0726 10.5309 17.0426 12.9609 17.0426C15.391 17.0426 17.3609 15.0726 17.3609 12.6426C17.3609 10.2125 15.391 8.24258 12.9609 8.24258C12.6572 8.24258 12.4109 7.99633 12.4109 7.69258Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.486 10.6469C13.6275 10.3781 13.9601 10.2748 14.2289 10.4163C15.5729 11.1237 16.089 12.7866 15.3817 14.1306C14.6743 15.4746 13.0113 15.9907 11.6673 15.2834C11.3985 15.1419 11.2953 14.8093 11.4368 14.5405C11.5783 14.2717 11.9108 14.1685 12.1796 14.31C12.9861 14.7344 13.9838 14.4247 14.4082 13.6183C14.8327 12.8119 14.523 11.8141 13.7166 11.3897C13.4478 11.2483 13.3446 10.9157 13.486 10.6469Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_51084">
          <rect width="14.3" height="22" fill="white" transform="translate(5.8125 1.56641)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicAppleWatch;
