import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicEyeDrops = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2019_51200)">
        <path
          d="M17.0054 12.0164H8.94854C8.61411 12.0164 8.35709 11.7204 8.40397 11.3893L9.0502 6.82527C9.08557 6.57548 9.20886 6.34776 9.38173 6.16402C10.4902 4.98585 10.8946 3.56982 11.066 2.55224C11.1598 1.9954 11.6194 1.56641 12.1841 1.56641H12.8007H13.4372C13.9934 1.56641 14.4567 1.98346 14.5845 2.52478C14.9064 3.88882 15.5451 5.27144 16.296 6.03512C16.4921 6.23463 16.6508 6.47494 16.7012 6.75016L17.5464 11.3674C17.6082 11.7052 17.3488 12.0164 17.0054 12.0164Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M8.04688 13.0914C8.04688 12.1801 8.78561 11.4414 9.69687 11.4414H16.2969C17.2081 11.4414 17.9469 12.1801 17.9469 13.0914V21.8914C17.9469 22.8027 17.2081 23.5414 16.2969 23.5414H9.69688C8.78561 23.5414 8.04688 22.8027 8.04688 21.8914V13.0914Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M8.04688 13.6602H17.9469V21.9102H8.04688V13.6602Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M8.04688 11.2875C8.04688 10.68 8.53936 10.1875 9.14687 10.1875H16.8469C17.4544 10.1875 17.9469 10.68 17.9469 11.2875C17.9469 11.895 17.4544 12.3875 16.8469 12.3875H9.14687C8.53936 12.3875 8.04688 11.895 8.04688 11.2875Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M12.8924 16.845C11.4081 16.8433 10.4779 18.0517 10.1055 18.656C10.5271 19.1364 11.5249 20.0974 12.8855 20.099C14.2461 20.1006 15.3304 19.1419 15.6098 18.6623C15.3843 18.0573 14.3767 16.8467 12.8924 16.845Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.456 18.6266C10.9182 19.1116 11.7802 19.8227 12.8903 19.824C13.5056 19.8247 14.0663 19.6079 14.5106 19.3285C14.8827 19.0945 15.1553 18.8279 15.3014 18.6344C15.1763 18.3846 14.9221 18.0398 14.5532 17.7399C14.1237 17.3907 13.5601 17.1199 12.8961 17.1192C11.6895 17.1178 10.8701 18.0166 10.456 18.6266ZM9.87514 18.512C10.2535 17.8979 11.2554 16.5681 12.8972 16.57C13.7175 16.571 14.3998 16.9063 14.9015 17.3141C15.3983 17.718 15.7395 18.2102 15.8722 18.566L15.9174 18.6875L15.8521 18.7996C15.6844 19.0876 15.3069 19.4767 14.8041 19.7929C14.2962 20.1123 13.6344 20.374 12.8891 20.3731C11.4013 20.3714 10.3329 19.327 9.90241 18.8364L9.76893 18.6843L9.87514 18.512Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M14.0149 18.5063C13.9897 19.1053 13.4766 19.5713 12.8688 19.547C12.261 19.5228 11.7887 19.0176 11.8138 18.4186C11.839 17.8196 12.3521 17.3536 12.9599 17.3779C13.5677 17.4021 14.04 17.9073 14.0149 18.5063Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43043 16.8799C9.53052 16.7656 9.70449 16.7538 9.819 16.8535L10.8099 17.7164C10.9244 17.8161 10.9361 17.9895 10.836 18.1038C10.7359 18.218 10.562 18.2298 10.4475 18.1301L9.45654 17.2673C9.34202 17.1676 9.33033 16.9941 9.43043 16.8799Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5403 16.7917C16.4378 16.6798 16.2636 16.6721 16.1513 16.7744L15.1796 17.6603C15.0673 17.7626 15.0595 17.9363 15.162 18.0482C15.2646 18.1601 15.4388 18.1678 15.5511 18.0654L16.5228 17.1796C16.6351 17.0772 16.6429 16.9035 16.5403 16.7917Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9092 15.7301C11.041 15.6502 11.2119 15.6883 11.2909 15.8153L11.9746 16.9136C12.0536 17.0406 12.0109 17.2083 11.8791 17.2883C11.7473 17.3682 11.5764 17.3301 11.4974 17.2031L10.8137 16.1048C10.7347 15.9778 10.7774 15.8101 10.9092 15.7301Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0403 15.6757C14.9076 15.5974 14.7384 15.6383 14.6623 15.7673L14.0039 16.8828C13.9278 17.0117 13.9737 17.1798 14.1063 17.2581C14.2389 17.3365 14.4081 17.2955 14.4842 17.1666L15.1427 16.051C15.2187 15.9221 15.1729 15.7541 15.0403 15.6757Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9124 15.1341C13.0682 15.1356 13.1949 15.2567 13.1954 15.4046L13.1996 16.6814C13.2001 16.8293 13.0742 16.9479 12.9184 16.9464C12.7626 16.9449 12.6359 16.8238 12.6354 16.6759L12.6312 15.3991C12.6307 15.2512 12.7566 15.1326 12.9124 15.1341Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_51200">
          <rect width="9.9" height="22" fill="white" transform="translate(8.04688 1.56641)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicEyeDrops;
