import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicKey = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2511_78301)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0783 9.4305L3.4644 17.4975C3.12594 17.7815 3.08179 18.2861 3.36579 18.6246L4.00621 19.3878C4.17209 19.5855 4.39728 19.7244 4.64837 19.784L5.92618 20.0873C6.29527 20.1749 6.68404 20.0828 6.97463 19.839L7.90223 19.0606L8.33732 19.3503C8.53575 19.4824 8.79752 19.4679 8.98014 19.3147C9.16595 19.1588 9.22418 18.8977 9.12223 18.6776L8.90853 18.2162L11.1991 16.2942L11.6342 16.5839C11.8326 16.716 12.0944 16.7015 12.277 16.5483C12.4628 16.3924 12.521 16.1313 12.4191 15.9112L12.2054 15.4498L12.6314 15.0924L13.0665 15.3821C13.2649 15.5142 13.5267 15.4997 13.7093 15.3465C13.8951 15.1906 13.9533 14.9294 13.8514 14.7094L13.6377 14.248L15.6815 12.533L13.0783 9.4305Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M3.46094 18.7266L12.9633 10.7904L13.3731 11.2787L3.88021 19.2132L3.46094 18.7266Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1022 12.3862C23.3868 10.4692 23.6848 7.06312 21.7678 4.77852C19.8508 2.49392 16.4447 2.19592 14.1601 4.11293C11.8755 6.02994 11.5775 9.43602 13.4945 11.7206C15.4115 14.0052 18.8176 14.3032 21.1022 12.3862ZM20.1121 7.68529C20.6043 7.27232 20.6685 6.53858 20.2555 6.04642C19.8426 5.55427 19.1088 5.49007 18.6167 5.90304C18.1245 6.31601 18.0603 7.04975 18.4733 7.54191C18.8862 8.03406 19.62 8.09826 20.1121 7.68529Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.588 11.7734C22.5341 10.1404 22.7879 7.23889 21.1549 5.29275C19.5219 3.34661 16.6205 3.09276 14.6743 4.72577C12.7282 6.35878 12.4743 9.26025 14.1073 11.2064C15.7403 13.1525 18.6418 13.4064 20.588 11.7734ZM21.1022 12.3862C23.3868 10.4692 23.6848 7.06312 21.7678 4.77852C19.8508 2.49392 16.4447 2.19592 14.1601 4.11293C11.8755 6.02994 11.5775 9.43602 13.4945 11.7206C15.4115 14.0052 18.8176 14.3032 21.1022 12.3862Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_78301">
          <rect width="23" height="10.8" fill="white" transform="translate(0.679688 15.4258) rotate(-40)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicKey;
