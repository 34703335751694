import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicSchool = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9488 5.32802C13.2068 5.32802 14.2267 4.35916 14.2267 3.16401C14.2267 1.96886 13.2068 1 11.9488 1C10.6907 1 9.6709 1.96886 9.6709 3.16401C9.6709 4.35916 10.6907 5.32802 11.9488 5.32802ZM11.9492 3.68849C12.2542 3.68849 12.5014 3.45362 12.5014 3.16388C12.5014 2.87415 12.2542 2.63927 11.9492 2.63927C11.6442 2.63927 11.397 2.87415 11.397 3.16388C11.397 3.45362 11.6442 3.68849 11.9492 3.68849Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M1.11279 15.6751C1.11279 13.8215 2.61543 12.3188 4.46902 12.3188H4.877C6.87597 12.3188 8.49646 13.9393 8.49646 15.9383V19.7682C8.49646 20.3637 8.01377 20.8463 7.41834 20.8463H2.79091C1.86411 20.8463 1.11279 20.095 1.11279 19.1682V15.6751Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M22.9277 15.6751C22.9277 13.8215 21.4251 12.3188 19.5715 12.3188H19.1635C17.1646 12.3188 15.5441 13.9393 15.5441 15.9383V19.7682C15.5441 20.3637 16.0268 20.8463 16.6222 20.8463H21.2496C22.1764 20.8463 22.9277 20.095 22.9277 19.1682V15.6751Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M3.46191 11.622C3.46191 6.98803 7.2185 3.23145 11.8525 3.23145H12.188C16.822 3.23145 20.5786 6.98803 20.5786 11.622V20.5005C20.5786 21.8808 19.4596 22.9997 18.0793 22.9997H5.9612C4.58088 22.9997 3.46191 21.8808 3.46191 20.5004L3.46191 11.622Z"
        fill={isAct ? theme.colors.secondary : theme.colors.gray2}
      />
      <path
        d="M18.3972 21.0231V17.578C18.3972 14.0562 15.5422 11.2012 12.0204 11.2012C8.49855 11.2012 5.64355 14.0562 5.64355 17.578V21.0231C5.64355 22.114 6.5279 22.9984 7.6188 22.9984H16.4219C17.5128 22.9984 18.3972 22.114 18.3972 21.0231Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
    </Svg>
  );
};

export default GraphicSchool;
