import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicSportSwear = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2529_61390)">
        <rect
          x="10.5078"
          y="2.0625"
          width="5.34109"
          height="4.50036"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M9.69803 1.47885L9.02734 1.71838C8.40447 1.94083 8.17486 2.70115 8.39031 3.32648C9.27347 5.88986 7.52159 8.55683 6.1794 9.91525C5.98073 10.1163 5.85938 10.3833 5.85938 10.666V21.3328C5.85938 21.7329 6.09657 22.0978 6.47218 22.2354C10.9696 23.883 16.4909 23.0184 19.3202 22.2046C19.7325 22.086 20.0034 21.7034 20.0034 21.2743V10.8803C20.0034 10.4808 19.7555 10.1316 19.4296 9.90068C17.5012 8.53424 17.5204 5.22102 17.8894 3.05277C17.9761 2.54359 17.7019 2.03017 17.2145 1.85916L16.3307 1.54905C15.761 1.34916 15.1376 1.69813 14.8847 2.24634C14.5035 3.07252 13.9393 3.87736 13.4662 4.46329C13.1678 4.83289 12.6592 4.86724 12.382 4.48148C11.9177 3.83533 11.3708 2.82558 10.9543 1.9932C10.723 1.53085 10.1849 1.30497 9.69803 1.47885Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <mask id="mask0_2529_61390" mask="alpha" maskUnits="userSpaceOnUse" x="5" y="1" width="15" height="23">
          <path
            d="M9.69803 1.47885L9.02734 1.71838C8.40447 1.94083 8.17486 2.70115 8.39031 3.32648C9.27347 5.88986 7.52159 8.55683 6.1794 9.91525C5.98073 10.1163 5.85938 10.3833 5.85938 10.666V21.3328C5.85938 21.7329 6.09657 22.0978 6.47218 22.2354C10.9696 23.883 16.4909 23.0184 19.3202 22.2046C19.7325 22.086 20.0034 21.7034 20.0034 21.2743V10.8803C20.0034 10.4808 19.7555 10.1316 19.4296 9.90068C17.5012 8.53424 17.5204 5.22102 17.8894 3.05277C17.9761 2.54359 17.7019 2.03017 17.2145 1.85916L16.3307 1.54905C15.761 1.34916 15.1376 1.69813 14.8847 2.24634C14.5035 3.07252 13.9393 3.87736 13.4662 4.46329C13.1678 4.83289 12.6592 4.86724 12.382 4.48148C11.9177 3.83533 11.3708 2.82558 10.9543 1.9932C10.723 1.53085 10.1849 1.30497 9.69803 1.47885Z"
            fill={isAct ? theme.colors.primary : theme.colors.gray3}
          />
        </mask>
        <g mask="url(#mask0_2529_61390)">
          <path
            d="M18.2743 10.8161C16.3753 7.09708 17.1863 2.70552 17.8293 0.974609H20.8956V23.7237H18.2743V10.8161Z"
            fill={isAct ? theme.colors.secondary : theme.colors.gray2}
          />
          <path
            d="M7.68659 10.8161C9.58565 7.09708 8.77459 2.70552 8.13168 0.974609H5.06537V23.7237H7.68659V10.8161Z"
            fill={isAct ? theme.colors.secondary : theme.colors.gray2}
          />
        </g>
        <circle cx="10.7078" cy="8.53987" r="0.989091" fill={isAct ? theme.colors.secondary : theme.colors.gray2} />
      </g>
      <defs>
        <clipPath id="clip0_2529_61390">
          <rect width="14.144" height="22" fill="white" transform="translate(5.85938 1.17188)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicSportSwear;
