import Svg, { Props } from '../svg/Svg';

const IconOverflow = ({ ...rest }: Props) => (
  <Svg {...rest}>
    <path
      d="M5 13.75C5.9665 13.75 6.75 12.9665 6.75 12C6.75 11.0335 5.9665 10.25 5 10.25C4.0335 10.25 3.25 11.0335 3.25 12C3.25 12.9665 4.0335 13.75 5 13.75Z"
      fill="#9090A0"
    />
    <path
      d="M12.079 13.75C13.0455 13.75 13.829 12.9665 13.829 12C13.829 11.0335 13.0455 10.25 12.079 10.25C11.1125 10.25 10.329 11.0335 10.329 12C10.329 12.9665 11.1125 13.75 12.079 13.75Z"
      fill="#9090A0"
    />
    <path
      d="M19.16 13.75C20.1265 13.75 20.91 12.9665 20.91 12C20.91 11.0335 20.1265 10.25 19.16 10.25C18.1935 10.25 17.41 11.0335 17.41 12C17.41 12.9665 18.1935 13.75 19.16 13.75Z"
      fill="#9090A0"
    />
  </Svg>
);

export default IconOverflow;
