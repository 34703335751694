import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicRun = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <path
        d="M22.8388 9.10938L19.3044 2.92044C19.128 2.61154 18.7697 2.45508 18.4232 2.53561C17.8569 2.66722 17.3752 3.03734 17.1022 3.55063L17.0538 3.64159C16.8125 4.09518 16.4226 4.45198 15.9494 4.65213L15.1086 5.00781C14.282 5.35745 13.3741 5.46898 12.4875 5.32982L11.2012 5.12793C10.1724 4.96645 9.15683 5.48688 8.68708 6.41631L4.74052 14.2248C4.55813 14.5857 4.28753 14.8946 3.95383 15.123L1.04472 17.1135C0.390923 17.5608 0 18.302 0 19.0942V19.1997C0 20.5252 1.07451 21.5997 2.4 21.5997H6.02621C6.46376 21.5997 6.89298 21.4801 7.26746 21.2538L21.996 12.3537C23.1107 11.6801 23.4847 10.2404 22.8388 9.10938Z"
        fill={isAct ? theme.colors.secondary : theme.colors.gray2}
      />
      <path
        d="M7.26746 21.2539L21.994 12.3549C23.1095 11.6809 23.4832 10.2398 22.8357 9.10862L22.6875 8.84961L6.53532 18.7697C6.15747 19.0018 5.72271 19.1246 5.27929 19.1246H0V19.1998C0 20.5253 1.07451 21.5998 2.4 21.5998H6.02621C6.46376 21.5998 6.89298 21.4802 7.26746 21.2539Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M10.8911 10.0541C11.0378 9.75698 10.9166 9.39573 10.6204 9.24722L7.9385 7.90275L7.40717 8.97871L10.089 10.3232C10.3853 10.4717 10.7444 10.3512 10.8911 10.0541Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M9.67505 12.4571C9.82108 12.1596 9.69904 11.7986 9.40247 11.6508L6.71753 10.3125L6.18874 11.3897L8.87367 12.728C9.17024 12.8758 9.52903 12.7545 9.67505 12.4571Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
    </Svg>
  );
};

export default GraphicRun;
