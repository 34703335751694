import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicUmbrella = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2511_78517)">
        <path
          d="M12.1406 11.1797H13.8141V19.1585H12.1406V11.1797Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M18.2667 20.568V19.3385C18.2667 18.9424 17.9456 18.6213 17.5495 18.6213C17.1534 18.6213 16.8323 18.9424 16.8323 19.3385V20.1154C16.8323 20.9406 16.1559 21.6096 15.3307 21.6096C14.4972 21.6096 13.8141 20.934 13.8141 20.1005V18.1422C13.8141 18.1262 13.8011 18.1133 13.7852 18.1133H12.1824C12.1593 18.1133 12.1406 18.132 12.1406 18.155V20.5381C12.1406 21.9881 13.3161 23.1635 14.7661 23.1635H15.6711C17.1046 23.1635 18.2667 22.0015 18.2667 20.568Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M12.8848 2.39453C5.89454 2.39453 2.8655 8.39499 1.97278 12.3721C1.8736 12.8139 2.4191 13.0989 2.79618 12.8481C5.27781 11.1978 7.61957 12.2948 8.82278 13.3682C9.0266 13.55 9.34125 13.556 9.54602 13.3752C12.317 10.9293 14.9911 12.1606 16.2977 13.3616C16.4993 13.5468 16.8148 13.557 17.0235 13.3797C19.4315 11.3352 21.8804 12.1136 23.3019 13.1092C23.6241 13.3349 24.0155 13.1639 23.9546 12.7752C23.5214 10.0079 21.2118 2.39453 12.8848 2.39453Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <mask id="mask0_2511_78517" mask="alpha" maskUnits="userSpaceOnUse" x="9" y="2" width="8" height="12">
          <path
            d="M9.65886 8.04371C10.2233 5.71517 11.7287 3.00913 12.6525 2.44767C12.7577 2.38377 12.8877 2.39397 12.9943 2.45542C15.5662 3.93813 16.4628 10.0228 16.5531 13.2444C16.5605 13.5089 16.3458 13.7215 16.0812 13.7215H9.55523C9.34943 13.7215 9.1675 13.5898 9.13302 13.3869C9.01719 12.7053 8.92583 11.0676 9.65886 8.04371Z"
            fill={isAct ? theme.colors.primary : theme.colors.gray3}
          />
        </mask>
        <g mask="url(#mask0_2511_78517)">
          <path
            d="M12.8848 2.39453C5.89454 2.39453 2.8655 8.39499 1.97278 12.3721C1.8736 12.8139 2.4191 13.0989 2.79618 12.8481C5.27781 11.1978 7.61957 12.2948 8.82278 13.3682C9.0266 13.55 9.34125 13.556 9.54602 13.3752C12.317 10.9293 14.9911 12.1606 16.2977 13.3616C16.4993 13.5468 16.8148 13.557 17.0235 13.3797C19.4315 11.3352 21.8804 12.1136 23.3019 13.1092C23.6241 13.3349 24.0155 13.1639 23.9546 12.7752C23.5214 10.0079 21.2118 2.39453 12.8848 2.39453Z"
            fill={isAct ? theme.colors.primary : theme.colors.gray3}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2511_78517">
          <rect width="22" height="20.7683" fill="white" transform="translate(1.96094 2.39453)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicUmbrella;
