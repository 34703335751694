import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const Graphic10Kg = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2019_51128)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9922 22.6719C18.7912 22.6719 23.4922 17.9709 23.4922 12.1719C23.4922 6.37289 18.7912 1.67188 12.9922 1.67188C7.1932 1.67188 2.49219 6.37289 2.49219 12.1719C2.49219 17.9709 7.1932 22.6719 12.9922 22.6719ZM12.9901 14.9671C14.5339 14.9671 15.7854 13.7156 15.7854 12.1719C15.7854 10.6281 14.5339 9.37666 12.9901 9.37666C11.4464 9.37666 10.1949 10.6281 10.1949 12.1719C10.1949 13.7156 11.4464 14.9671 12.9901 14.9671Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0131 20.9402C17.8312 20.9402 21.7371 17.0344 21.7371 12.2162C21.7371 7.39807 17.8312 3.49219 13.0131 3.49219C8.19494 3.49219 4.28906 7.39807 4.28906 12.2162C4.28906 17.0344 8.19494 20.9402 13.0131 20.9402ZM13.0094 15.2085C14.6617 15.2085 16.0012 13.8691 16.0012 12.2167C16.0012 10.5644 14.6617 9.22495 13.0094 9.22495C11.3571 9.22495 10.0176 10.5644 10.0176 12.2167C10.0176 13.8691 11.3571 15.2085 13.0094 15.2085Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4658 15.09C14.8539 17.0083 11.9921 17.2568 10.0737 15.6448C8.15538 14.0329 7.90697 11.1711 9.51888 9.25276C11.1308 7.33442 13.9926 7.08601 15.911 8.69792C17.8293 10.3098 18.0777 13.1717 16.4658 15.09ZM11.1691 14.3412C12.3675 15.3482 14.1552 15.193 15.1622 13.9946C16.1692 12.7963 16.014 11.0085 14.8156 10.0015C13.6172 8.99456 11.8294 9.14974 10.8225 10.3481C9.81552 11.5465 9.9707 13.3343 11.1691 14.3412Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3353 12.1558C23.3331 12.626 22.9501 13.0053 22.4799 13.0031L16.8881 12.9761C16.4179 12.9738 16.0386 12.5908 16.0409 12.1206C16.0432 11.6505 16.4262 11.2711 16.8963 11.2734L22.4881 11.3004C22.9583 11.3027 23.3376 11.6857 23.3353 12.1558ZM10.1347 12.1914C10.1411 12.6616 9.76514 13.0479 9.29499 13.0542L3.57258 13.1317C3.10244 13.1381 2.71615 12.7621 2.70978 12.292C2.70341 11.8219 3.07938 11.4356 3.54952 11.4292L9.27193 11.3517C9.74208 11.3453 10.1284 11.7213 10.1347 12.1914Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M10.5853 17.4732L10.1812 17.4735L9.67208 17.7982L9.6723 18.1766L10.1445 17.8804L10.1559 17.8804L10.1568 19.5337L10.5865 19.5334L10.5853 17.4732Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M11.7768 19.5612C12.2918 19.5609 12.6046 19.1794 12.6042 18.5021C12.6038 17.8292 12.2892 17.4438 11.7755 17.4441C11.2619 17.4444 10.9477 17.8301 10.9481 18.5031C10.9485 19.1775 11.2603 19.5615 11.7768 19.5612ZM11.3863 18.5029C11.386 18.0319 11.5381 17.7956 11.7757 17.7941C12.0133 17.7954 12.1671 18.0315 12.166 18.5024C12.1677 18.9776 12.0156 19.2082 11.7766 19.2083C11.5375 19.2085 11.3837 18.9781 11.3863 18.5029Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M12.8247 19.5321L13.2515 19.5319L13.2512 18.9428L13.4673 18.6809L14.0398 19.5314L14.552 19.5311L13.7745 18.3933L14.5422 17.4709L14.0272 17.4712L13.2793 18.3851L13.2509 18.3851L13.2503 17.4717L12.8235 17.4719L12.8247 19.5321Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M15.9207 18.1331L16.3532 18.1329C16.296 17.7274 15.9387 17.4417 15.4735 17.4419C14.93 17.4422 14.5119 17.8352 14.5123 18.5039C14.5127 19.1555 14.9056 19.5593 15.4833 19.559C16.0026 19.5587 16.3737 19.2284 16.3734 18.6878L16.3733 18.4317L15.5139 18.4322L15.5141 18.7537L15.9609 18.7534C15.9553 19.0124 15.7804 19.179 15.4859 19.1806C15.1572 19.1793 14.9479 18.9333 14.9477 18.4979C14.9474 18.0654 15.1636 17.822 15.4823 17.8204C15.7071 17.8217 15.8622 17.9397 15.9207 18.1331Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_51128">
          <rect width="21" height="21" fill="white" transform="translate(2.49219 1.67188)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Graphic10Kg;
