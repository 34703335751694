import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicToescoks = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <path
        d="M3.90314 22.0434C3.46799 22.1008 2.57314 22.1655 2.39055 21.119C2.22488 20.1695 3.05944 19.4803 3.61819 19.1857L5.89549 18.4364L7.74187 15.1738L13.6137 19.8658L10.1444 22.8384C8.97809 23.549 8.25337 22.9763 7.99143 22.7694L7.98524 22.7645C7.40422 23.4116 6.59525 23.0479 6.29424 22.7266C5.65781 23.2104 5.26942 22.7485 5.09324 22.453C4.46341 22.8068 4.00705 22.3499 3.90314 22.0434Z"
        fill={isAct ? theme.colors.secondary : theme.colors.gray2}
      />
      <path
        d="M4.2391 19.7865C4.02102 19.1723 3.52974 19.2292 3.31135 19.3345C3.93207 18.6393 4.85129 18.8178 5.42577 18.1434C6.9958 15.6578 9.14407 12.2508 11.1032 8.96762C11.9077 7.61949 10.4303 6.13777 10.3033 4.86208C14.6285 4.95749 16.3392 2.18205 17.1029 0.986272C17.552 2.07402 19.1718 3.89953 20.6782 5.59728L20.76 5.68944C22.298 7.42306 22.0583 10.0082 20.4728 11.8596C16.9526 15.97 12.9409 21.3215 12.5432 21.9187C12.0312 22.6875 10.8888 22.8704 10.0821 22.8136C10.0674 21.0891 8.34074 21.085 7.77867 21.3507C7.51828 20.3112 6.62243 20.5738 6.20706 20.8351C5.95089 19.8381 5.33889 19.9962 5.06492 20.1999C4.81243 19.6341 4.40917 19.6886 4.2391 19.7865Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M8.66974 16.6678C7.31214 16.0634 8.96905 13.5922 10.3365 11.4469C10.702 10.7188 11.2053 9.68802 11.8408 10.0868C13.1429 10.9039 14.3496 8.8798 14.2588 10.3679C14.1526 12.1086 10.3667 17.4232 8.66974 16.6678Z"
        fill={isAct ? theme.colors.secondary : theme.colors.gray2}
      />
    </Svg>
  );
};

export default GraphicToescoks;
