import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicStrap = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2529_61432)">
        <path
          d="M7.44856 18.9615L17.1406 13.3018L15.0396 11.6608C14.7187 11.4101 14.2778 11.3791 13.9249 11.5824L5.79178 16.2683C5.28826 16.5584 5.13721 17.2152 5.4634 17.6961L6.11668 18.6593C6.41201 19.0947 6.99422 19.2269 7.44856 18.9615Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M18.1607 22.5212L6.69531 13.4231L7.45293 12.6622C7.70039 12.4137 8.05996 12.313 8.40052 12.3968L10.5084 12.9155C10.6442 12.9489 10.7714 13.0104 10.8818 13.096L19.9776 20.1415C20.4042 20.4719 20.4917 21.0811 20.1755 21.5182L19.5925 22.324C19.2585 22.7856 18.607 22.8754 18.1607 22.5212Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M13.4769 10.168H10.4531C9.41717 10.168 8.55268 10.9591 8.46099 11.991L7.71686 20.3664C7.61292 21.5362 8.53458 22.5434 9.70901 22.5434H14.6316C15.8448 22.5434 16.7786 21.472 16.6129 20.2702L15.4582 11.8948C15.3218 10.9052 14.476 10.168 13.4769 10.168Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M10.5688 1.67671C9.81055 0.991301 6.70155 0.991301 6.89112 4.91336C7.03284 7.84536 7.91397 9.54266 8.42073 10.1358L15.4231 10.2443C15.7643 9.40649 16.6288 7.83776 16.9018 4.91336C17.1748 1.98895 14.7406 0.914847 13.4136 1.67671C12.2307 0.762406 11.1 1.29575 10.5688 1.67671Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M5.27263 4.66336C5.41496 5.47867 6.65452 7.30787 7.57616 8.22548C7.77929 6.60384 7.97349 3.30878 7.1252 3.10165C6.06483 2.84274 5.10078 3.67897 5.27263 4.66336Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M6.29806 12.404L6.64637 10.2641C6.70473 9.90558 6.95149 9.60314 7.30053 9.50262C10.3251 8.63158 14.2731 9.0854 16.4277 9.53658C16.8182 9.61836 17.1099 9.93823 17.174 10.332L17.4896 12.2707C17.6064 12.9884 16.9385 13.598 16.2198 13.4876C13.2936 13.0382 9.77726 13.281 7.46961 13.5864C6.78446 13.677 6.18702 13.0861 6.29806 12.404Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
      </g>
      <defs>
        <clipPath id="clip0_2529_61432">
          <rect width="15.8833" height="22" fill="white" transform="translate(4.85938 1.17188)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicStrap;
