import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicPouch = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2511_78403)">
        <path
          d="M18.875 6.77734H6.85932C6.52774 6.77734 6.22342 6.9676 6.06883 7.27155L2.26865 14.7433C1.01431 17.2095 2.74017 20.1734 5.43062 20.1734H20.2725C22.9577 20.1734 24.6839 17.2202 23.4401 14.7544L19.6669 7.27433C19.5128 6.96884 19.2077 6.77734 18.875 6.77734Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75378 8.19348C6.69359 8.19348 6.63594 8.21864 6.5939 8.26328L4.59509 10.3855C4.33659 10.66 3.9123 10.6653 3.64741 10.3975C3.38253 10.1296 3.37735 9.69001 3.63585 9.41554L5.63467 7.2933C5.92894 6.98086 6.33244 6.80469 6.75378 6.80469H18.9702C19.378 6.80469 19.7697 6.96981 20.0618 7.26484L22.0354 9.25868C22.3004 9.5264 22.3057 9.96603 22.0474 10.2406C21.789 10.5152 21.3647 10.5208 21.0997 10.253L19.1261 8.25921C19.0844 8.21707 19.0284 8.19348 18.9702 8.19348H6.75378Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M12.7957 5.42965C12.7957 6.1243 12.315 6.68742 11.7221 6.68742C11.1291 6.68742 10.6484 6.1243 10.6484 5.42965C10.6484 4.735 11.1291 4.17188 11.7221 4.17188C12.315 4.17188 12.7957 4.735 12.7957 5.42965Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M9.79688 6.68884C9.79688 6.42166 9.98175 6.20508 10.2098 6.20508H12.3846C12.6127 6.20508 12.7975 6.42166 12.7975 6.68884C12.7975 6.95601 12.6127 7.1726 12.3846 7.1726H10.2098C9.98175 7.1726 9.79688 6.95601 9.79688 6.68884Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M12.7981 5.42965C12.7981 6.1243 13.2787 6.68742 13.8717 6.68742C14.4646 6.68742 14.9453 6.1243 14.9453 5.42965C14.9453 4.735 14.4646 4.17188 13.8717 4.17188C13.2787 4.17188 12.7981 4.735 12.7981 5.42965Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M15.7969 6.68884C15.7969 6.42166 15.612 6.20508 15.3839 6.20508H13.2091C12.9811 6.20508 12.7962 6.42166 12.7962 6.68884C12.7962 6.95601 12.9811 7.1726 13.2091 7.1726H15.3839C15.612 7.1726 15.7969 6.95601 15.7969 6.68884Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_78403">
          <rect width="22" height="16.0015" fill="white" transform="translate(1.85156 4.17188)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicPouch;
