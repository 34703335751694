import Svg, { Props } from '../svg/Svg';

const GraphicEmptyCard = ({ width = 233, height = 208, viewBox = '0 0 233 208', ...rest }: Props) => {
  return (
    <Svg width={width} height={height} viewBox={viewBox} {...rest}>
      <g opacity="0.2">
        <ellipse opacity="0.6" cx="42.7864" cy="178.975" rx="28.2424" ry="3.05324" fill="#C7C7D0" />
        <ellipse opacity="0.8" cx="135.41" cy="197.454" rx="79.2911" ry="8.5443" fill="#C7C7D0" />
        <g opacity="0.6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9528 127.661C46.8448 126.869 51.7899 120.64 50.9979 113.748C50.206 106.856 43.977 101.911 37.085 102.703C30.193 103.495 25.2479 109.724 26.0399 116.616C26.8318 123.508 33.0608 128.453 39.9528 127.661ZM38.8624 118.209C40.5332 118.017 41.732 116.507 41.5401 114.836C41.3481 113.165 39.838 111.967 38.1672 112.159C36.4964 112.351 35.2976 113.861 35.4896 115.531C35.6816 117.202 37.1917 118.401 38.8624 118.209Z"
            fill="#9090A0"
          />
          <rect
            x="12.4346"
            y="118.185"
            width="52.909"
            height="52.5284"
            rx="7.61281"
            transform="rotate(-6.55488 12.4346 118.185)"
            fill="#C7C7D0"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.152 34.6081C156.534 35.7036 165.028 28.9861 166.123 19.6041C167.219 10.2221 160.501 1.72846 151.119 0.632972C141.737 -0.462517 133.244 6.25501 132.148 15.637C131.053 25.019 137.77 33.5126 147.152 34.6081ZM148.653 21.7363C150.927 22.0019 152.986 20.3734 153.252 18.099C153.517 15.8245 151.889 13.7655 149.615 13.4999C147.34 13.2343 145.281 14.8628 145.016 17.1372C144.75 19.4116 146.378 21.4707 148.653 21.7363Z"
          fill="#9090A0"
        />
        <path
          d="M56.1697 103.877C57.7838 90.0537 70.2983 80.1561 84.1216 81.7702L87.1644 82.1255C102.072 83.8662 112.746 97.3622 111.005 112.27L107.279 144.178C106.761 148.619 102.741 151.798 98.3002 151.28L63.7906 147.25C56.8789 146.443 51.9301 140.186 52.7372 133.274L56.1697 103.877Z"
          fill="#9090A0"
        />
        <path
          d="M218.868 122.877C220.482 109.054 210.585 96.5397 196.761 94.9256L193.718 94.5703C178.811 92.8296 165.315 103.503 163.574 118.411L159.848 150.32C159.33 154.76 162.509 158.78 166.95 159.299L201.459 163.328C208.371 164.135 214.628 159.186 215.435 152.275L218.868 122.877Z"
          fill="#9090A0"
        />
        <path
          d="M77.2307 72.0895C81.2659 37.5312 112.552 12.7873 147.11 16.8225L149.613 17.1148C184.172 21.15 208.916 52.4362 204.88 86.9945L196.243 160.964C195.041 171.258 185.722 178.629 175.428 177.427L85.0559 166.874C74.762 165.672 67.3916 156.353 68.5936 146.059L77.2307 72.0895Z"
          fill="#C7C7D0"
        />
        <path
          d="M180.139 163.001L183.68 132.679C186.746 106.415 167.941 82.6372 141.677 79.5704C115.413 76.5037 91.635 95.309 88.5682 121.573L85.0276 151.896C84.0777 160.031 89.9027 167.396 98.0382 168.346L163.689 176.012C171.824 176.962 179.189 171.137 180.139 163.001Z"
          fill="#9090A0"
        />
      </g>
    </Svg>
  );
};

export default GraphicEmptyCard;
