import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicCharger = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2508_60897)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4491 13.5508V20.8954C21.4491 22.3704 20.2268 23.5662 18.719 23.5662L9.84727 23.5662C8.33949 23.5662 7.11719 22.3704 7.11719 20.8954V18.4796H8.48223V20.8954C8.48223 21.6329 9.09338 22.2308 9.84727 22.2308H18.719C19.4729 22.2308 20.0841 21.6329 20.0841 20.8954V13.5508H21.4491Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M5.65625 2.12282C5.65625 1.81552 5.90536 1.56641 6.21266 1.56641C6.51996 1.56641 6.76907 1.81552 6.76907 2.12282V4.34847C6.76907 4.65576 6.51996 4.90488 6.21266 4.90488C5.90536 4.90488 5.65625 4.65576 5.65625 4.34847V2.12282Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M9 2.12282C9 1.81552 9.24911 1.56641 9.55641 1.56641C9.86371 1.56641 10.1128 1.81552 10.1128 2.12282V4.34847C10.1128 4.65576 9.86371 4.90488 9.55641 4.90488C9.24911 4.90488 9 4.65576 9 4.34847V2.12282Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M3.99219 5.07571C3.99219 4.46112 4.49042 3.96289 5.10501 3.96289H10.6691C11.2837 3.96289 11.782 4.46112 11.782 5.07571V7.64377C11.782 8.25836 11.2837 8.75659 10.6691 8.75659H5.10501C4.49042 8.75659 3.99219 8.25836 3.99219 7.64377V5.07571Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M5.10156 16.0327C5.10156 15.4181 5.59979 14.9199 6.21439 14.9199H9.55286C10.1675 14.9199 10.6657 15.4181 10.6657 16.0327V18.2584C10.6657 18.873 10.1675 19.3712 9.55286 19.3712H6.21439C5.59979 19.3712 5.10156 18.873 5.10156 18.2584V16.0327Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M2.32031 8.37236C2.32031 7.45047 3.06765 6.70312 3.98955 6.70312H11.7793C12.7012 6.70312 13.4485 7.45047 13.4485 8.37236V15.0493C13.4485 15.9712 12.7012 16.7185 11.7793 16.7185H3.98955C3.06765 16.7185 2.32031 15.9712 2.32031 15.0493V8.37236Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06725 9.5006C8.39762 9.65421 8.5464 10.0589 8.39957 10.4045L8.15407 10.9824H8.61964C8.84123 10.9824 9.04778 11.0997 9.16852 11.2941C9.28927 11.4884 9.30782 11.7335 9.21782 11.9454L8.56322 13.4862C8.41639 13.8318 8.02955 13.9875 7.69918 13.8338C7.36882 13.6802 7.22003 13.2755 7.36686 12.9299L7.61237 12.352H7.14679C6.9252 12.352 6.71866 12.2348 6.59791 12.0404C6.47717 11.846 6.45861 11.6009 6.54861 11.3891L7.20321 9.84826C7.35004 9.50265 7.73688 9.34699 8.06725 9.5006Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M18.4531 9.35696C18.4531 8.74237 18.9514 8.24414 19.5659 8.24414H21.7916C22.4062 8.24414 22.9044 8.74237 22.9044 9.35696V12.6954H18.4531V9.35696Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M17.8984 12.0952C17.8984 11.4806 18.3967 10.9824 19.0113 10.9824H22.3497C22.9643 10.9824 23.4626 11.4807 23.4626 12.0952V14.3209C23.4626 15.5501 22.4661 16.5465 21.2369 16.5465H20.1241C18.8949 16.5465 17.8984 15.5501 17.8984 14.3209V12.0952Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2508_60897">
          <rect width="21.1436" height="22" fill="white" transform="translate(2.32031 1.56641)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicCharger;
