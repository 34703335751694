import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicRoaming = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79378 5.55175C6.17399 5.88818 6.20227 6.4612 5.85696 6.83162C4.38241 8.41338 3.48497 10.5117 3.48497 12.8164C3.48497 15.1211 4.38241 17.2194 5.85696 18.8012C6.20227 19.1716 6.17399 19.7446 5.79378 20.0811C5.41357 20.4175 4.82541 20.3899 4.48009 20.0195C2.70683 18.1173 1.625 15.5889 1.625 12.8164C1.625 10.0439 2.70683 7.5155 4.48009 5.61331C4.82541 5.24288 5.41357 5.21532 5.79378 5.55175ZM19.4562 5.55175C19.8364 5.21532 20.4246 5.24288 20.7699 5.61331C22.5432 7.5155 23.625 10.0439 23.625 12.8164C23.625 15.5889 22.5432 18.1173 20.7699 20.0195C20.4246 20.3899 19.8364 20.4175 19.4562 20.0811C19.076 19.7446 19.0477 19.1716 19.393 18.8012C20.8676 17.2194 21.765 15.1211 21.765 12.8164C21.765 10.5117 20.8676 8.41338 19.393 6.83162C19.0477 6.4612 19.076 5.88818 19.4562 5.55175Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1249 5.17729C19.7073 4.66197 20.6047 4.70281 21.1356 5.27236C22.9906 7.26221 24.125 9.91082 24.125 12.8164C24.125 15.722 22.9906 18.3706 21.1356 20.3604C20.6047 20.93 19.7073 20.9708 19.1249 20.4555C18.535 19.9335 18.4894 19.0373 19.0273 18.4602C20.4201 16.9662 21.265 14.988 21.265 12.8164C21.265 10.6448 20.4201 8.66663 19.0273 7.17256C18.4894 6.59553 18.535 5.69927 19.1249 5.17729ZM20.4042 5.95424C20.2445 5.78294 19.9656 5.76866 19.7876 5.9262C19.617 6.07708 19.6061 6.32685 19.7588 6.49068C21.3151 8.16012 22.265 10.3787 22.265 12.8164C22.265 15.2541 21.3151 17.4727 19.7588 19.1421C19.6061 19.3059 19.617 19.5557 19.7876 19.7066C19.9656 19.8641 20.2445 19.8499 20.4042 19.6786C22.0957 17.864 23.125 15.4558 23.125 12.8164C23.125 10.177 22.0957 7.76878 20.4042 5.95424ZM4.11436 5.27236C4.6453 4.70281 5.54273 4.66197 6.12511 5.17729C6.71501 5.69927 6.7606 6.59554 6.22269 7.17256C4.82989 8.66663 3.98497 10.6448 3.98497 12.8164C3.98497 14.988 4.82989 16.9662 6.22269 18.4602C6.7606 19.0373 6.71501 19.9335 6.12511 20.4555C5.54273 20.9708 4.6453 20.93 4.11436 20.3604C2.25939 18.3706 1.125 15.722 1.125 12.8164C1.125 9.91082 2.25939 7.26221 4.11436 5.27236ZM5.46244 5.9262C5.2844 5.76866 5.00552 5.78294 4.84583 5.95424C3.15427 7.76878 2.125 10.177 2.125 12.8164C2.125 15.4558 3.15427 17.864 4.84583 19.6786C5.00552 19.8499 5.2844 19.8641 5.46244 19.7066C5.63296 19.5557 5.64394 19.3059 5.49122 19.1421C3.93493 17.4727 2.98497 15.2541 2.98497 12.8164C2.98497 10.3787 3.93493 8.16012 5.49122 6.49068C5.64394 6.32685 5.63296 6.07708 5.46244 5.9262Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00498 8.19548C9.35247 8.52494 9.36004 9.06647 9.02187 9.40502C8.14086 10.2871 7.59966 11.4892 7.59966 12.8162C7.59966 14.1431 8.14086 15.3452 9.02187 16.2273C9.36004 16.5658 9.35247 17.1074 9.00498 17.4368C8.65748 17.7663 8.10164 17.7589 7.76348 17.4204C6.57634 16.2319 5.84375 14.6065 5.84375 12.8162C5.84375 11.0258 6.57634 9.40044 7.76348 8.21194C8.10164 7.87339 8.65748 7.86602 9.00498 8.19548ZM16.2359 8.19548C16.5834 7.86602 17.1393 7.87339 17.4774 8.21194C18.6646 9.40044 19.3971 11.0258 19.3971 12.8162C19.3971 14.6065 18.6646 16.2319 17.4774 17.4204C17.1393 17.7589 16.5834 17.7663 16.2359 17.4368C15.8884 17.1074 15.8809 16.5658 16.219 16.2273C17.1 15.3452 17.6412 14.1431 17.6412 12.8162C17.6412 11.4892 17.1 10.2871 16.219 9.40502C15.8809 9.06647 15.8884 8.52494 16.2359 8.19548Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43279 7.87773C7.95202 7.35791 8.80004 7.34699 9.33302 7.8523C9.87295 8.36422 9.88514 9.21134 9.35911 9.73797C8.56164 10.5364 8.07495 11.6203 8.07495 12.8155C8.07495 14.0107 8.56164 15.0947 9.35911 15.8931C9.88514 16.4197 9.87295 17.2668 9.33302 17.7787C8.80004 18.2841 7.95201 18.2731 7.43279 17.7533C6.16217 16.4812 5.375 14.7378 5.375 12.8155C5.375 10.8933 6.16217 9.1498 7.43279 7.87773ZM8.68349 8.53739C8.52148 8.38378 8.25782 8.3876 8.10072 8.54489C6.99706 9.64981 6.31905 11.1571 6.31905 12.8155C6.31905 14.474 6.99706 15.9812 8.10072 17.0861L7.76675 17.4197L8.10072 17.0861C8.25782 17.2434 8.52148 17.2473 8.68349 17.0936C8.83855 16.9466 8.84148 16.7107 8.69119 16.5602C7.72662 15.5946 7.13091 14.2743 7.13091 12.8155C7.13091 11.3567 7.72662 10.0365 8.69119 9.07081C8.84148 8.92034 8.83855 8.6844 8.68349 8.53739ZM15.9144 7.8523C16.4474 7.34699 17.2954 7.35791 17.8147 7.87773C19.0853 9.1498 19.8724 10.8933 19.8724 12.8155C19.8724 14.7378 19.0853 16.4812 17.8147 17.7533C17.2954 18.2731 16.4474 18.284 15.9144 17.7787C15.3745 17.2668 15.3623 16.4197 15.8883 15.8931C16.6858 15.0947 17.1725 14.0107 17.1725 12.8155C17.1725 11.6203 16.6858 10.5364 15.8883 9.73797L16.2223 9.40439L15.8883 9.73797C15.3623 9.21134 15.3745 8.36422 15.9144 7.8523ZM17.1467 8.54489C16.9896 8.3876 16.726 8.38378 16.564 8.53739C16.4089 8.6844 16.406 8.92034 16.5563 9.07081C17.5208 10.0365 18.1165 11.3567 18.1165 12.8155C18.1165 14.2743 17.5208 15.5946 16.5563 16.5602C16.406 16.7107 16.4089 16.9466 16.564 17.0936C16.726 17.2473 16.9896 17.2434 17.1467 17.0861C18.2504 15.9812 18.9284 14.474 18.9284 12.8155C18.9284 11.1571 18.2504 9.64981 17.1467 8.54489Z"
        fill={isAct ? theme.colors.primary : theme.colors.gray3}
      />
      <path
        d="M15.4036 12.8179C15.4036 14.3116 14.1607 15.5224 12.6276 15.5224C11.0944 15.5224 9.85156 14.3116 9.85156 12.8179C9.85156 11.3242 11.0944 10.1133 12.6276 10.1133C14.1607 10.1133 15.4036 11.3242 15.4036 12.8179Z"
        fill={isAct ? theme.colors.secondary : theme.colors.gray2}
      />
    </Svg>
  );
};

export default GraphicRoaming;
