import { useTheme } from '@emotion/react';

import Svg from '../svg/Svg';

import { GraphicProps } from './type';

const GraphicHandBag = ({ isAct = false, ...rest }: GraphicProps) => {
  const theme = useTheme();

  return (
    <Svg {...rest}>
      <g clipPath="url(#clip0_2019_51098)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.77155 5.09182C6.69391 6.41463 6.16151 8.02456 6.02249 9.12659C5.9862 9.41426 5.72358 9.61804 5.43591 9.58175C5.14824 9.54547 4.94446 9.28285 4.98075 8.99518C5.14051 7.72873 5.7375 5.92619 6.95748 4.42865C8.19088 2.91464 10.0659 1.71094 12.7321 1.71094C15.3921 1.71094 17.2726 2.87498 18.5403 4.32961C19.7954 5.76986 20.4512 7.49264 20.7139 8.66565C20.7773 8.94859 20.5993 9.22933 20.3164 9.29271C20.0334 9.35609 19.7527 9.1781 19.6893 8.89516C19.4541 7.84514 18.8609 6.29568 17.7487 5.01945C16.6491 3.75761 15.0438 2.76093 12.7321 2.76093C10.4266 2.76093 8.83577 3.78547 7.77155 5.09182Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
        <path
          d="M12.5789 12.0881C9.45807 12.0881 7.25825 10.1154 6.04644 8.40058C5.74567 7.97498 4.95987 8.0943 4.86777 8.60725L2.57151 21.3954C2.4559 22.0393 2.95082 22.631 3.60498 22.631H22.0943C22.7485 22.631 23.2434 22.0393 23.1278 21.3954L20.7897 8.37439C20.7016 7.88372 19.9962 7.75316 19.6931 8.14897C18.3402 9.91594 15.8536 12.0881 12.5789 12.0881Z"
          fill={isAct ? theme.colors.primary : theme.colors.gray3}
        />
        <path
          d="M10.75 15.7691C10.75 15.4792 10.9851 15.2441 11.275 15.2441H14.425C14.7149 15.2441 14.95 15.4792 14.95 15.7691V17.3441C14.95 17.6341 14.7149 17.8691 14.425 17.8691H11.275C10.9851 17.8691 10.75 17.6341 10.75 17.3441V15.7691Z"
          fill={isAct ? theme.colors.secondary : theme.colors.gray2}
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_51098">
          <rect width="21" height="20.92" fill="white" transform="translate(2.35156 1.71094)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default GraphicHandBag;
